/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
import md5 from 'js-md5';

import { useSnackbarMessage } from 'Snackbar/store';
import useGetConfigFile from 'Devices/hooks/useGetConfigFile';
import { useId } from 'Devices/store';

import {
  FIREPLACE_SERVICE_UUID,
  FILE_WRITER_CHAR_UUID,
  REBOOT_DEVICE_CHAR_UUID,
  VERSION_DEVICE_CHAR_UUID,
} from '../constants';
import {
  useProgress,
  usePending,
  useBluetoothDevice
} from '../store';

const CHUNK_SIZE = 128;

const useConfigure = () => {
  const [,, getBluetoothDevice] = useBluetoothDevice();
  const [, setSnackbarMessage] = useSnackbarMessage();
  const [, setPending] = usePending();
  const [, setProgress] = useProgress();
  const [deviceId] = useId();
  const { getConfig } = useGetConfigFile();

  const getVersion = async () => {
    const device = getBluetoothDevice();
    const service = await device.gatt.getPrimaryService(FIREPLACE_SERVICE_UUID);
    const char = await service.getCharacteristic(VERSION_DEVICE_CHAR_UUID);
    const value = await char.readValue();
    return new TextDecoder().decode(value);
  };

  const uploadJSON = async (filename, data, onProgress = () => null, timeout = 10000) => {
    const json = JSON.stringify(data);
    const device = getBluetoothDevice();
    const service = await device.gatt.getPrimaryService(FIREPLACE_SERVICE_UUID);
    const char = await service.getCharacteristic(FILE_WRITER_CHAR_UUID);

    await char.startNotifications();

    let bytesSent = 0;
    let resolve = null;
    let reject = null;
    let bytes = new TextEncoder().encode(json);
    let chunk = bytes.slice(0, CHUNK_SIZE);
    let timeoutId = null;
    let ack;

    const totalHash = md5(bytes);
    const bytesTotal = bytes.length;

    const onAck = () => {
      resolve();
      clearTimeout(timeoutId);
    };

    char.addEventListener('characteristicvaluechanged', onAck);

    try {
      setPending(true);

      timeoutId = setTimeout(() => reject(new Error('Opening file for writing timed out')), timeout);
      ack = new Promise((res, rej) => { resolve = res; reject = rej; });

      await char.writeValue(new TextEncoder().encode(filename));
      await ack;

      // TODO: Read char for errors

      while (chunk.length) {
        timeoutId = setTimeout(() => reject(new Error('Writing file timed out')), timeout);
        onProgress((bytesSent / bytesTotal) * 100);
        ack = new Promise((res, rej) => { resolve = res; reject = rej; });
        await char.writeValue(chunk);
        await ack;

        // TODO: Read char for errors

        bytes = bytes.slice(CHUNK_SIZE);
        bytesSent += chunk.length;
        chunk = bytes.slice(0, CHUNK_SIZE);
      }

      let sentHash = await char.readValue();
      sentHash = new TextDecoder().decode(sentHash);

      if (totalHash !== sentHash) {
        throw new Error('Checksum mismatch');
      }
    } finally {
      char.stopNotifications();
      char.removeEventListener('characteristicvaluechanged', onAck);
    }
  };

  const uploadConfig = async () => {
    setSnackbarMessage('Acquiring device config...');
    const config = await getConfig(deviceId);
    const vrsion = await getVersion();
    setSnackbarMessage('Uploading config file to device...');
    await uploadJSON(`/conf/${vrsion}/config.json`, config, setProgress);
  };

  const connectDevice = async () => {
    const device = getBluetoothDevice();

    setSnackbarMessage('Connecting device to the cloud...');

    const service = await device.gatt.getPrimaryService(FIREPLACE_SERVICE_UUID);
    const char = await service.getCharacteristic(REBOOT_DEVICE_CHAR_UUID);

    let resolve;
    let reject;
    const timeoutId = setTimeout(() => reject(new Error('Connecting device timed out')), 60000);

    const onAck = () => {
      resolve();
      clearTimeout(timeoutId);
    };

    char.addEventListener('characteristicvaluechanged', onAck);
    const ack = new Promise((res, rej) => { resolve = res; reject = rej; });

    try {
      char.writeValue(new TextEncoder().encode('connect'));
      await ack;
      const res = new TextDecoder().decode(await char.readValue());

      if (res) {
        throw new Error(res);
      }

      setSnackbarMessage('Device start downloading latest software.');
    } catch (err) {
      setSnackbarMessage(`An error occurred during connecting device to the cloud: ${err.message}`);
    } finally {
      char.removeEventListener('characteristicvaluechanged', onAck);
      device.gatt.disconnect();
    }
  };

  return {
    configure: async () => {
      setPending(true);

      try {
        await uploadConfig();
        await connectDevice();
      } finally {
        setPending(false);
      }
    }
  };
};

export default useConfigure;
