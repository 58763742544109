import useApiClient from 'hooks/useApiClient';
import slugify from 'slugify';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import {
  accessPointSelector,
  accessPointsSelector,
  descriptionSelector,
  orderSelector,
  passwordSelector,
  savePendingState,
  ssidSelector
} from 'WiFi/store';

const useSave = (id, deviceId) => {
  const client = useApiClient();
  const setSavePending = useSetRecoilState(savePendingState);
  const [, seSnackbarMessage] = useSnackbarMessage();
  const ssid = useRecoilValue(ssidSelector(id));
  const password = useRecoilValue(passwordSelector(id));
  const description = useRecoilValue(descriptionSelector(id));
  const wifiId = id || slugify(ssid, { lower: true, strict: true }).substr(0, 10);
  const accessPoints = useRecoilValue(accessPointsSelector);
  const setAccessPoint = useSetRecoilState(accessPointSelector(wifiId));
  const url = (deviceId
    ? `/wifi/v1/devices/${deviceId}/wifi/${wifiId}`
    : `/wifi/v1/${wifiId}`);
  let order = useRecoilValue(orderSelector(id));

  if (!id) {
    // Add to the end of the list or create default to 10000
    order = accessPoints.length ? accessPoints[accessPoints.length - 1].order * 2 : 10000;
  }

  const save = async () => {
    setSavePending(true);

    try {
      const value = {
        ssid,
        password,
        description,
        order
      };
      await client.put(url, value);
      setAccessPoint(value);
      seSnackbarMessage('Wi-Fi saved');
    } catch (err) {
      seSnackbarMessage('Could not save Wi-Fi');
    } finally {
      setSavePending(false);
    }
  };

  return [save];
};

export default useSave;
