/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Autocomplete } from '@material-ui/lab';
import useDebounce from 'hooks/useDebounce';
import { TextField, CircularProgress } from '@material-ui/core';
import useSearch from '../hooks/useSearch';
import useFindOne from '../hooks/useFindOne';
import { hitsState, searchPendingState } from '../store';

const AppsAutocomplete = ({
  value,
  onChange
}) => {
  const [selectedValue, selectValue] = useState({ displayName: '' });
  const [search] = useSearch();
  const { findOne } = useFindOne();
  const hits = useRecoilValue(hitsState);
  const pending = useRecoilValue(searchPendingState);
  const [, setQuery] = useDebounce(pending, search);

  useEffect(() => {
    (async () => {
      if (!value) {
        return;
      }

      let hit = hits.find(({ objectID }) => objectID === value);

      try {
        if (!hit) {
          hit = await findOne(value);
        }

        selectValue(hit);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [value]);

  const handleChange = (e, v) => {
    selectValue(v);
    onChange(v.objectID);
  };

  return (
    <Autocomplete
      getOptionSelected={(option, val) => option.objectID === val.objectID}
      getOptionLabel={(option) => option.displayName}
      options={hits}
      loading={pending}
      value={selectedValue}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Application"
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {pending ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

AppsAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

AppsAutocomplete.defaultProps = {
  value: null
};

export default AppsAutocomplete;
