import useProjectIndex from 'hooks/useProjectIndex';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import {
  loadMorePendingState,
  searchResultsState,
  hitsState
} from '../store';

const useLoadMore = () => {
  const [getIndex] = useProjectIndex('apps');
  const setLoadMorePending = useSetRecoilState(loadMorePendingState);
  const [results, setResults] = useRecoilState(searchResultsState);
  const setHits = useSetRecoilState(hitsState);
  const [, setSnackbarMessage] = useSnackbarMessage();

  const loadMore = async () => {
    setLoadMorePending(true);

    try {
      const index = await getIndex();
      const res = await index.search(results.query, { page: results.page + 1, hitsPerPage: results.hitsPerPage });
      setResults(res);
      setHits(hits => ([...hits, ...res.hits]));
    } catch (err) {
      console.err(err);
      setSnackbarMessage('Could not load apps. Please try again later.');
    } finally {
      setLoadMorePending(false);
    }
  };

  return [loadMore];
};

export default useLoadMore;
