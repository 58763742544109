import React, { useRef } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import InfiniteScroller from 'react-infinite-scroller';
import { useRecoilValue } from 'recoil';

import Search from 'components/Search';
import Loader from 'components/Loader';
import Scrollable from 'components/Scrollable';
import useDeferred from 'hooks/useDeferred';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import useSearch from '../hooks/useSearch';
import useLoadMore from '../hooks/useLoadMore';

import {
  hasMoreSelector,
  hitsState,
  loadMorePendingState,
  searchPendingState,
} from '../store';
import AppsEmpty from './AppsEmpty';

const AppsList = () => {
  const hits = useRecoilValue(hitsState);
  const hasMore = useRecoilValue(hasMoreSelector);
  const root = useProjectRoot();
  const loadMorePending = useRecoilValue(loadMorePendingState);
  const searchPending = useRecoilValue(searchPendingState);
  const searchPendingDeferred = useDeferred(searchPending, 1000);
  const scrollParent = useRef();
  const [search] = useSearch();
  const [loadMore] = useLoadMore();
  const { appId } = useParams();

  const hasEntries = !!hits.length;

  return (
    <Box display="flex" flexDirection="column" flex="auto">
      <Box display="flex" pt={3}>
        <Box maxWidth={500} flex="auto" flexGrow={100}>
          <Search
            onSearch={search}
            isPending={searchPendingDeferred}
            TextFieldProps={{ fullWidth: true, variant: 'standard', size: 'medium' }}
          />
        </Box>
      </Box>
      <Box py={1} display="flex" flex="auto">
        {hasEntries
          ? (
            <Scrollable ref={scrollParent}>
              <List>
                <InfiniteScroller
                  hasMore={hasMore}
                  loader={loadMorePending && (<Box key={1}><Loader /></Box>)}
                  loadMore={loadMore}
                  initialLoad={false}
                  useWindow={false}
                  getScrollParent={() => scrollParent.current}
                  threshold={100}
                >
                  {hits.map(({ objectID, displayName }) => (
                    <ListItem
                      button
                      key={objectID}
                      divider
                      component={Link}
                      to={`${root}/apps/${objectID}`}
                      selected={appId === objectID}
                    >
                      <ListItemText primary={displayName} />
                    </ListItem>
                  ))}
                </InfiniteScroller>
              </List>
            </Scrollable>
          )
          : (
            !searchPendingDeferred && <AppsEmpty />
          )}
      </Box>
    </Box>
  );
};

export default AppsList;
