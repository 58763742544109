import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import useValidation from 'hooks/useValidation';
import useCreate from '../hooks/useCreate';
import {
  useCreateDialogOpen,
  useCanCreate,
  useCreatePending,
  useDisplayName,
} from '../store';
import useReset from '../hooks/useReset';

const CreateTokenDialog = () => {
  const [canCreate] = useCanCreate();
  const [createPending] = useCreatePending();
  const [create] = useCreate();
  const [createDialogOpen, setCreateDialogOpen] = useCreateDialogOpen();
  const [reset] = useReset();

  const [displayName, setDisplayName] = useDisplayName();
  const [, setCanCreate] = useCanCreate();

  const [
    displayNameHasError,
    displayNameErrorMessage,
    displayNameValid,
    onDisplayNameChanged,
    onDisplayNameBlurred
  ] = useValidation(
    displayName,
    (v) => (
      (!v && 'Required')
      || (v && v.length < 4 && 'Project Name should be at least 4 symbols long')
      || (v && v.trim().length > 50 && 'Project Name should be at most 50 symbols long')
    ),
    setDisplayName
  );

  useEffect(() => {
    setCanCreate(displayNameValid);
  }, [displayNameValid]);

  const handleSave = async () => {
    await create();
    reset();
    setCreateDialogOpen(false);
  };

  return (
    <Dialog
      open={createDialogOpen}
      onClose={() => setCreateDialogOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          Create new developer token
          <Box flex="auto" />
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={displayName}
          required
          fullWidth
          margin="dense"
          error={displayNameHasError}
          helperText={displayNameErrorMessage || ' '}
          onChange={(e) => onDisplayNameChanged(e.target.value)}
          onBlur={onDisplayNameBlurred}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCreateDialogOpen(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={!canCreate || createPending}
          startIcon={createPending && <CircularProgress size={24} color="inherit" />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTokenDialog;
