import { useState, useEffect } from 'react';

const useDebounce = (
  isPending,
  callback,
  debounce = 400,
) => {
  const [shouldSearch, setShouldSearch] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => setShouldSearch(true), debounce);
    return () => {
      clearTimeout(timeoutId);
      setShouldSearch(false);
    };
  }, [value]);

  useEffect(() => {
    if (!isPending && shouldSearch) {
      callback(value);
      setShouldSearch(false);
    }
  }, [isPending, shouldSearch]);

  return [value, setValue];
};

export default useDebounce;
