import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
} from '@material-ui/core';

const StringConfig = ({
  label,
  description,
  value,
  onChange,
  required,
  readOnly,
  pending
}) => {
  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const isMedium = useMediaQuery(({ breakpoints }) => breakpoints.only('md'));

  const showLabel = (!isSmall && !isMedium);

  return (
    <ListItem dense>
      <Box display="flex" width="100%">
        {showLabel && (
          <Box width={240}>
            <ListItemText primary={`${label} ${required ? '*' : ''}`} />
          </Box>
        )}
        <Box flex="auto">
          <TextField
            label={!showLabel && label}
            required={required}
            helperText={description || ' '}
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
            InputProps={{
              readOnly,
              endAdornment: pending && (
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
    </ListItem>
  );
};

StringConfig.propTypes = {
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  pending: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
};

StringConfig.defaultProps = {
  required: false,
  readOnly: false,
  pending: false,
  label: '',
  value: '',
  description: '',
};

export default StringConfig;
