import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const Loader = () => (
  <Box display="flex" flex="auto" justifyContent="center" alignItems="center" p={2}>
    <CircularProgress />
  </Box>
);

export default Loader;
