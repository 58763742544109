import { useEffect } from 'react';
import useProjectId from 'hooks/useProjectId';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import { useSetRecoilState } from 'recoil';
import { Revision } from 'utils/revision';
import { useSnackbarMessage } from 'Snackbar/store';
import { useGetOneResult } from 'Devices/store';

import { documentState, schemaState } from '../store';

const useVariables = (deviceId) => {
  const projectId = useProjectId();
  const firebase = useFireplaceFirebase();
  const [device] = useGetOneResult();
  const setDocument = useSetRecoilState(documentState);
  const setSchema = useSetRecoilState(schemaState);
  const [, setSnackbarMessage] = useSnackbarMessage();

  const getJsonSchema = async () => {
    try {
      const snapshot = await device.app.get();
      setSchema(snapshot.data().schemas.variables);
    } catch (err) {
      setSnackbarMessage('Could not get device variables shape.');
    }
  };

  useEffect(() => {
    if (device.app) {
      getJsonSchema();
    }
  }, [device.app]);

  useEffect(() => firebase.firestore()
    .doc(`projects/${projectId}/devices/${deviceId}/props/variables`)
    .onSnapshot({
      next: (snapshot) => {
        const { variables, rev } = snapshot.data();
        setDocument({ variables, rev: Revision.parse(rev) });
      },
      error: () => setSnackbarMessage('Could not get device variables.')
    }), []);
};

export default useVariables;
