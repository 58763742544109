import React from 'react';
import {
  Link
} from 'react-router-dom';
import useRouter from 'use-react-router';
import {
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Typography
} from '@material-ui/core';
import {
  Apps,
  Memory,
  Settings,
  SettingsEthernet,
  MergeType,
} from '@material-ui/icons';
import useProjectRoot from 'Projects/hooks/useProjectRoot';

import { useNavigationDrawerOpen } from '../store';

const NavigationDrawer = () => {
  const root = useProjectRoot();
  const { pathname } = useRouter().location;
  const [open, setOpen] = useNavigationDrawerOpen();

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box width={250}>
        <Toolbar disableGutters>
          <ListItem button component={Link} to="/">
            <Box alignItems="center" display="flex" flex="auto" color="warning.light">
              <Typography component="span" color="inherit" variant="h6">Thingware</Typography>
            </Box>
          </ListItem>
        </Toolbar>
        <Divider />
        <List>
          <ListItem
            button
            component={Link}
            to={`${root}/devices`}
            selected={pathname.startsWith(`${root}/devices`)}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <Memory />
            </ListItemIcon>
            <ListItemText primary="Devices" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={`${root}/apps`}
            selected={pathname.startsWith(`${root}/apps`)}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
            <ListItemText primary="Apps" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={`${root}/network`}
            selected={pathname.startsWith(`${root}/network`)}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <SettingsEthernet />
            </ListItemIcon>
            <ListItemText primary="Network" />
          </ListItem>
          <ListItem
            button
            divider
            component={Link}
            to={`${root}/integrations`}
            selected={pathname.startsWith(`${root}/integrations`)}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <MergeType />
            </ListItemIcon>
            <ListItemText primary="Integrations" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to={`${root}/settings`}
            selected={pathname.startsWith(`${root}/settings`)}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
