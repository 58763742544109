import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import useProjectId from 'hooks/useProjectId';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import { accessPointsState, getAllPendingState } from 'WiFi/store';

const useGetAll = (deviceId) => {
  const projectId = useProjectId();
  const fireplace = useFireplaceFirebase();
  const setGetAllPending = useSetRecoilState(getAllPendingState);
  const setAccessPoints = useSetRecoilState(accessPointsState);
  const resetAccessPoints = useResetRecoilState(accessPointsState);
  const [, setSnackbarMessage] = useSnackbarMessage();
  const query = deviceId
    ? fireplace.firestore()
      .collection('projects')
      .doc(projectId)
      .collection('devices')
      .doc(deviceId)
      .collection('wifi')
    : fireplace.firestore()
      .collection('projects')
      .doc(projectId)
      .collection('wifi');

  const getAll = async () => {
    setGetAllPending(true);

    try {
      const { docs } = await query.get();
      setAccessPoints(docs.reduce((acc, doc) => ({
        ...acc,
        [doc.id]: doc.data()
      }), {}));
    } catch (err) {
      console.error(err);
      resetAccessPoints();
      setSnackbarMessage('Could not get Wi-Fi access points.');
    } finally {
      setGetAllPending(false);
    }
  };

  return [getAll];
};

export default useGetAll;
