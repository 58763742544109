import React, { useEffect } from 'react';
import {
  Box
} from '@material-ui/core';
import { useBackTo } from 'Projects/store';
import DevicesList from './DevicesList';

const List = () => {
  const [, setBackTo] = useBackTo();

  useEffect(() => {
    setBackTo(null);
  }, []);

  return (
    <Box display="flex" flex="auto" p={2} pt={0}>
      <DevicesList />
    </Box>
  );
};

export default List;
