import useApiClient from 'hooks/useApiClient';
import { useGetConfigFilePending, useGetConfigFileResult } from '../store';

const useGetConfigFile = () => {
  const apiClient = useApiClient();
  const [, setGetConfigFilePending] = useGetConfigFilePending();
  const [, setGetConfigFileResult] = useGetConfigFileResult();

  return {
    getConfig: async (id) => {
      setGetConfigFilePending(true);

      try {
        const { data } = await apiClient.get(`devices/v1/${id}/config`);
        setGetConfigFileResult(data);
        return data;
      } catch (err) {
        setGetConfigFileResult(null);
        throw err;
      } finally {
        setGetConfigFilePending(false);
      }
    },
  };
};

export default useGetConfigFile;
