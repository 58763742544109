import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
} from '@material-ui/core';

import { useBluetoothDevice, usePending } from 'Bluetooth/store';

import useSave from '../hooks/useSave';
import DeviceDetails from './DeviceDetails';

import {
  useAddOneHit,
  useId,
  useDisplayName,
  useCreateDialogOpen,
  useCanSave,
} from '../store';

import useReset from '../hooks/useReset';


const AddDeviceDialog = () => {
  const [device] = useBluetoothDevice();
  const [bluetoothPending] = usePending();
  const [canSaveDevice] = useCanSave();

  const { save } = useSave();
  const [addOne] = useAddOneHit();

  const [deviceId] = useId();
  const [createDialogOpen, setCreateDialogOpen] = useCreateDialogOpen();
  const [displayName] = useDisplayName();
  const [reset] = useReset();

  const handleSave = async () => {
    await save();

    reset();
    addOne({ objectID: deviceId, displayName });
    setCreateDialogOpen(false);
  };

  return (
    <Dialog
      open={createDialogOpen}
      onClose={() => setCreateDialogOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          Add device
          <Box flex="auto" />
        </Box>
      </DialogTitle>
      <DialogContent>
        <DeviceDetails isNew />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCreateDialogOpen(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={!canSaveDevice || bluetoothPending}
        >
          Save
          {device && ' & Configure'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeviceDialog;
