import React from 'react';
import { Box, Button, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { showCreateDialogState, canAddMoreSelector } from '../store';

const AddAccessPoint = () => {
  const canAddMore = useRecoilValue(canAddMoreSelector);
  const setCreateDialogVisible = useSetRecoilState(showCreateDialogState);

  return (
    <Box flex="auto" display="flex" alignItems="center">
      <Tooltip title={canAddMore ? '' : 'Only 10 Wi-Fi access points allowed'}>
        <Box>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            disabled={!canAddMore}
            onClick={() => setCreateDialogVisible(true)}
          >
            Add
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default AddAccessPoint;
