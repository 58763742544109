import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import { useSnackbarMessage } from 'Snackbar/store';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useProjectId from 'hooks/useProjectId';
import {
  saveWebhookPendingState,
  webhookState
} from '../store';

const useSave = () => {
  const projectId = useProjectId();
  const firebase = useFireplaceFirebase();
  const setSavePending = useSetRecoilState(saveWebhookPendingState);
  const webhook = useRecoilValue(webhookState);
  const [, setSnackbarMessage] = useSnackbarMessage();

  const save = async () => {
    setSavePending(true);

    try {
      await firebase.firestore()
        .collection(`projects/${projectId}/integrations`)
        .doc('webhook')
        .set(webhook);
      setSnackbarMessage('Webhook saved.');
    } catch (err) {
      setSnackbarMessage('Could not save webhook. Please try again.');
    } finally {
      setSavePending(false);
    }
  };

  return [save];
};

export default useSave;
