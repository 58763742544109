/* eslint-disable no-control-regex */
import { atom, selector, selectorFamily } from 'recoil';
import Joi from 'joi';

export const getAllPendingState = atom({ key: 'WiFi/getAllPendingState', default: true });
export const getOnePendingState = atom({ key: 'WiFi/getOnePendingState', default: false });
export const savePendingState = atom({ key: 'WiFi/savePendingState', default: false });
export const showCreateDialogState = atom({ key: 'WiFi/showCreateDialogState', default: false });
export const showDeleteDialogState = atom({ key: 'WiFi/showDeleteDialogState', default: false });
export const accessPointsState = atom({ key: 'WiFi/accessPointsState', default: {} });
export const accessPointsIdState = atom({ key: 'WiFi/accessPointsIdState', default: null });

export const accessPointsSelector = selector({
  key: 'WiFi/accessPointsSelector',
  get: ({ get }) => Object.entries(get(accessPointsState))
    .map(([id, accessPoint]) => ({ id, ...accessPoint }))
    .sort(({ order: orderA }, { order: orderB }) => orderA - orderB),
});

export const accessPointSelector = selectorFamily({
  key: 'WiFi/accessPointSelector',
  get: id => ({ get }) => get(accessPointsState)[id],
  set: id => ({ set }, value) => set(accessPointsState, accessPoints => ({ ...accessPoints, [id]: value }))
});

export const newAccessPoint = atom({
  key: 'WiFi/newAccessPoint',
  default: {
    ssid: '',
    password: '',
    description: '',
    order: 0
  },
});

const makeFieldSelector = (key) => ({
  get: id => ({ get }) => (id
    ? get(accessPointSelector(id))?.[key]
    : get(newAccessPoint)[key]
  ),
  set: id => ({ set }, value) => (id
    ? set(accessPointSelector(id), accessPoint => ({ ...accessPoint, [key]: value }))
    : set(newAccessPoint, accessPoint => ({ ...accessPoint, [key]: value }))
  )
});

export const ssidSelector = selectorFamily({ key: 'WiFi/ssidSelector', ...makeFieldSelector('ssid') });
export const passwordSelector = selectorFamily({ key: 'WiFi/passwordSelector', ...makeFieldSelector('password') });
export const descriptionSelector = selectorFamily({ key: 'WiFi/descriptionSelector', ...makeFieldSelector('description') });
export const orderSelector = selectorFamily({ key: 'WiFi/orderSelector', ...makeFieldSelector('order') });

export const ssidValidationSelector = selectorFamily({
  key: 'WiFi/ssidValidationSelector',
  get: id => ({ get }) => Joi.string()
    .label('SSID')
    .min(4)
    .max(32)
    .required()
    .regex(/^[\x00-\x7F]+$/, { name: 'ASCII' })
    .custom((v) => {
      if (!get(accessPointsSelector).some(({ ssid }) => ssid !== v)) {
        return v;
      }

      throw Joi.ValidationError('this SSID is already used');
    })
    .validate(get(ssidSelector(id)))
});

export const passwordValidationSelector = selectorFamily({
  key: 'WiFi/passwordValidationSelector',
  get: id => ({ get }) => Joi.string()
    .label('Password')
    .min(8)
    .max(64)
    .regex(/^[\x00-\x7F]+$/, { name: 'ASCII' })
    .optional()
    .allow('')
    .validate(get(passwordSelector(id))),
});

export const descriptionValidationSelector = selectorFamily({
  key: 'WiFi/passwordValidationSelector',
  get: id => ({ get }) => Joi.string()
    .label('Description')
    .max(300)
    .optional()
    .allow('')
    .validate(get(descriptionSelector(id))),
});

export const canAddMoreSelector = selector({
  key: 'WiFi/canAddMoreSelector',
  get: ({ get }) => get(accessPointsSelector).length < 10
});

export const canSaveSelector = selectorFamily({
  key: 'WiFi/canSaveSelector',
  get: id => ({ get }) => !get(ssidValidationSelector(id)).error
    && !get(passwordValidationSelector(id)).error
    && !get(descriptionValidationSelector(id)).error
});
