import React from 'react';
import {
  Link,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Box,
  Container,
  Tabs,
  Tab,
} from '@material-ui/core';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import usePageTitle from 'Projects/hooks/usePageTitle';
import { navTabState } from '../store';
import Webhook from './Webhook';

const Integrations = () => {
  const root = useProjectRoot();
  const navTab = useRecoilValue(navTabState);

  usePageTitle('Integrations');

  return (
    <Box display="flex" flex="auto">
      <Container disableGutters>
        <Box display="flex" flexDirection="column" height="100%">
          <Box p={2} pb={0}>
            <Tabs value={navTab}>
              <Tab label="Webhook" value="webhook" component={Link} to={`${root}/integrations/webhook`} />
            </Tabs>
          </Box>
          <Switch>
            <Route path="/projects/:projectId/integrations/webhook">
              <Webhook />
            </Route>
            <Redirect from={`${root}/integrations`} to={`${root}/integrations/webhook`} />
          </Switch>
        </Box>
      </Container>
    </Box>
  );
};

export default Integrations;
