import useProjectIndex from 'hooks/useProjectIndex';
import { useSetRecoilState } from 'recoil';
import { searchOnePendingState, searchOneResultsState } from '../store';

const useFindOne = () => {
  const [getIndex] = useProjectIndex('apps');
  const setFindOnePending = useSetRecoilState(searchOnePendingState);
  const setFindOneResult = useSetRecoilState(searchOneResultsState);

  return {
    findOne: async (objectID) => {
      setFindOnePending(true);

      try {
        const index = await getIndex();
        const res = await index.getObject(objectID);
        setFindOneResult(res);
        return res;
      } catch (err) {
        setFindOneResult(null);
        console.error(err);
        throw err;
      } finally {
        setFindOnePending(false);
      }
    },
  };
};

export default useFindOne;
