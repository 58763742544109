import React from 'react';
import {
  Box,
} from '@material-ui/core';

import usePageTitle from '../hooks/usePageTitle';
import ProjectsHeader from './ProjectsHeader';
import ProjectsList from './ProjectsList';
import CreateProjectDialog from './CreateProjectDialog';

const Projects = () => {
  usePageTitle('Projects');

  return (
    <Box display="flex" flex="auto" flexDirection="column">
      <ProjectsHeader />
      <Box flex="auto" display="flex" p={2} pt={0}>
        <ProjectsList />
      </Box>
      <CreateProjectDialog />
    </Box>
  );
};

export default Projects;
