import { useEffect, useState } from 'react';
import useFireplaceFirebase from '../../hooks/useFireplaceFirebase';
import useProjectId from '../../hooks/useProjectId';
import useApiClient from '../../hooks/useApiClient';

const useTenantAuth = () => {
  const projectId = useProjectId();
  const client = useApiClient();
  const firebase = useFireplaceFirebase();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isPending, setPending] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (firebase.auth().currentUser.tenantId !== projectId) {
          setPending(true);
          const { data: { customToken } } = await client.get(
            `/credentials/v1/projects/${projectId}/token`
          );
          firebase.auth().tenantId = projectId;
          await firebase.auth().signInWithCustomToken(customToken);
        }

        setAuthenticated(true);
      } catch {
        setAuthenticated(false);
      } finally {
        setPending(false);
      }
    })();
  }, [projectId]);

  return { isAuthenticated, isPending };
};

export default useTenantAuth;
