import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';
import StringConfig from './StringConfig';
import NumberConfig from './NumberConfig';
import BooleanConfig from './BooleanConfig';
import { schemaState, valueSelector } from '../store';

const DeviceConfigItem = ({ property }) => {
  const [value, setValue] = useRecoilState(valueSelector(property));
  const { properties, required } = useRecoilValue(schemaState);
  const { title, description, type } = properties[property];

  const Input = useMemo(() => ({
    string: StringConfig,
    number: NumberConfig,
    integer: NumberConfig,
    boolean: BooleanConfig
  }[type]), [type]);

  return (
    <Input
      label={title ?? property}
      description={description}
      value={value}
      onChange={setValue}
      required={required?.includes(property)}
    />
  );
};

DeviceConfigItem.propTypes = {
  property: PropTypes.string.isRequired
};

export default DeviceConfigItem;
