import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  CircularProgress,
} from '@material-ui/core';

import useValidation from 'hooks/useValidation';
import useDebounce from 'hooks/useDebounce';

import AppsAutocomplete from 'Apps/components/AppsAutocomplete';
import useFindOneDevice from '../hooks/useFindOne';

import {
  useFindOnePending,
  useId,
  useDisplayName,
  useDeviceApp,
  useFindOneResult,
  useCanSave
} from '../store';

const DeviceDetails = ({ isNew }) => {
  const [isFindOnePending] = useFindOnePending();
  const [deviceId, setDeviceId] = useId();
  const [displayName, setDisplayName] = useDisplayName();
  const [deviceApp, setDeviceApp] = useDeviceApp();
  const [findOneResult] = useFindOneResult();
  const [, setCanSave] = useCanSave();

  const { findOne: findOneDevice } = useFindOneDevice();
  const [, setFindOneLookup] = useDebounce(isFindOnePending, findOneDevice);
  const [
    deviceIdHasError,
    deviceIdErrorMessage,
    deviceIdValid,
    onDeviceIdChanged,
    onDeviceIdBlurred
  ] = useValidation(
    deviceId,
    (v) => (
      (!v && 'Required')
      || (v && v.length < 4 && 'Device ID should be at least 4 symbols long')
      || (v && v.trim().length > 32 && 'Device ID should be at most 32 symbols long')
      || (v
          && !/^[a-zA-Z][a-zA-Z0-9-_]+[a-zA-Z0-9]$/.test(v.trim())
          // eslint-disable-next-line max-len
          && 'Device ID should contain only letters, numbers hyphens and underscores. Can start only with letter and end with letter or number'
      )
      || (v && findOneResult?.objectID === v && 'This device ID is already used')
    ),
    (v) => {
      setDeviceId(v);
      setFindOneLookup(v);
    }
  );
  const [
    deviceNameHasError,
    deviceNameErrorMessage,
    deviceNameValid,
    onDeviceNameChanged,
    onDeviceNameBlurred
  ] = useValidation(
    displayName,
    (v) => (
      (!v && 'Required')
      || (v && v.length < 4 && 'Device ID should be at least 4 symbols long')
      || (v && v.trim().length > 50 && 'Device Name should be at most 50 symbols long')
    ),
    setDisplayName
  );

  useEffect(() => {
    setCanSave(deviceIdValid && deviceNameValid && !isFindOnePending);
  }, [deviceIdValid, deviceNameValid, isFindOnePending]);

  return (
    <Fragment>
      <TextField
        label="ID"
        value={deviceId}
        required={isNew}
        disabled={!isNew}
        fullWidth
        margin="dense"
        error={deviceIdHasError}
        helperText={deviceIdErrorMessage || 'Note: ID cannot be changed after creation'}
        onChange={(e) => onDeviceIdChanged(e.target.value)}
        onBlur={onDeviceIdBlurred}
        InputProps={{ endAdornment: (isFindOnePending && <CircularProgress size={20} />) }}
      />
      <TextField
        label="Name"
        value={displayName}
        required
        fullWidth
        margin="dense"
        error={deviceNameHasError}
        helperText={deviceNameErrorMessage || ' '}
        onChange={(e) => onDeviceNameChanged(e.target.value)}
        onBlur={onDeviceNameBlurred}
      />
      <AppsAutocomplete onChange={setDeviceApp} value={deviceApp} />
    </Fragment>
  );
};

DeviceDetails.propTypes = {
  isNew: PropTypes.bool
};

DeviceDetails.defaultProps = {
  isNew: false
};

export default DeviceDetails;
