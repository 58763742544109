import React, { useEffect } from 'react';
import {
  Box,
  Button
} from '@material-ui/core';
import usePageTitle from 'Projects/hooks/usePageTitle';
import { useNavTab } from 'ProjectSettings/store';
import { Add } from '@material-ui/icons';
import Scrollable from 'components/Scrollable';
import Search from 'components/Search';
import useDeferred from 'hooks/useDeferred';
import useSearch from '../hooks/useSearch';
import {
  useHits,
  useCreateDialogOpen,
  useSearchPending
} from '../store';
import CreateTokenDialog from './CreateTokenDialog';
import Token from './Token';
import DeleteTokenDialog from './DeleteTokenDialog';

const Tokens = () => {
  usePageTitle('Developer Tokens');
  const [searchPending] = useSearchPending();
  const [, setNavTab] = useNavTab();
  const [, setCreateDialogOpen] = useCreateDialogOpen();
  const { search } = useSearch();
  const [hits] = useHits();
  const searchPendingDeferred = useDeferred(searchPending);

  useEffect(() => {
    setNavTab('tokens');
  }, []);

  return (
    <Box p={1} display="flex" flex="auto" flexDirection="column" height="100%">
      <CreateTokenDialog />
      <DeleteTokenDialog />
      <Box display="flex" p={1}>
        <Box maxWidth={400} flex="auto" flexGrow={100} pr={1}>
          <Search
            onSearch={search}
            isPending={searchPendingDeferred}
            TextFieldProps={{ fullWidth: true, variant: 'standard', size: 'medium' }}
          />
        </Box>
        <Box flex="auto" />
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => setCreateDialogOpen(true)}
        >
          Add
        </Button>
      </Box>
      <Box py={1} display="flex" flex="auto">
        <Scrollable>
          <Box p={1}>
            {hits.map(({ objectID, displayName }) => (
              <Token key={objectID} objectID={objectID} displayName={displayName} />
            ))}
          </Box>
        </Scrollable>
      </Box>
    </Box>
  );
};

export default Tokens;
