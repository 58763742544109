import { useEffect } from 'react';
import useProjectId from 'hooks/useProjectId';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import { useOnline } from '../store';

const KEEP_ALIVE = 300 * 1000;

const useDeviceOnline = (deviceId) => {
  const [isOnline, setOnline] = useOnline();
  const firebase = useFireplaceFirebase();
  const projectId = useProjectId();

  useEffect(() => firebase.firestore()
    .doc(`projects/${projectId}/devices/${deviceId}/props/connection`)
    .onSnapshot({
      next: (snapshot) => {
        const { lastSeen, online } = snapshot.data();
        setOnline(online && lastSeen.toMillis() + KEEP_ALIVE > new Date());
      },
    }), []);

  return isOnline;
};

export default useDeviceOnline;
