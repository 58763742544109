import { useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import {
  accessPointsState, savePendingState
} from 'WiFi/store';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import useProjectId from 'hooks/useProjectId';
import { useHistory } from 'react-router-dom';
import useProjectRoot from 'Projects/hooks/useProjectRoot';

const useRemove = (id, deviceId) => {
  const { push } = useHistory();
  const projectId = useProjectId();
  const root = useProjectRoot();
  const client = useFireplaceFirebase();
  const [, seSnackbarMessage] = useSnackbarMessage();
  const setAccessPoints = useSetRecoilState(accessPointsState);
  const setPending = useSetRecoilState(savePendingState);
  const parentUrl = deviceId
    ? `${root}/devices/${deviceId}/network/wi-fi`
    : `${root}/network/wi-fi`;

  const remove = async () => {
    try {
      setPending(true);
      const ref = deviceId
        ? client.firestore()
          .collection('projects')
          .doc(projectId)
          .collection('devices')
          .doc(deviceId)
          .collection('wifi')
          .doc(id)
        : client.firestore()
          .collection('projects')
          .doc(projectId)
          .collection('wifi')
          .doc(id);
      await ref.delete();
      await push(parentUrl);
      setAccessPoints(accessPoints => Object.entries(accessPoints)
        .filter(([key]) => key !== id)
        .reduce((acc, [key, accessPoint]) => ({ ...acc, [key]: accessPoint }), {})
      );
      seSnackbarMessage('Wi-Fi removed');
    } catch (err) {
      console.error(err);
      seSnackbarMessage('Could not remove Wi-Fi. Please try again later.');
    } finally {
      setPending(false);
    }
  };

  return [remove];
};

export default useRemove;
