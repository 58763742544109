import React, { useEffect } from 'react';
import {
  Card, CardContent, Box
} from '@material-ui/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useReadme from 'Apps/hooks/useReadme';
import { useParams } from 'react-router-dom';
import Markdown from 'components/Markdown';
import { readmeState, selectedTabState } from '../store';


const Readme = () => {
  const { appId } = useParams();
  const selectTab = useSetRecoilState(selectedTabState);
  const readme = useRecoilValue(readmeState);
  const [getReadme] = useReadme(appId);

  useEffect(() => {
    selectTab('readme');
    getReadme();
  }, []);

  return (
    <Box p={2}>
      <Card>
        <CardContent>
          <Markdown>{readme}</Markdown>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Readme;
