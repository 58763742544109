import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Box,
  CircularProgress,
} from '@material-ui/core';
import useCreate from '../hooks/useCreate';
import ProjectDetails from './ProjectDetails';
import {
  useCreateDialogOpen,
  useCanSave,
  useCreatePending,
} from '../store';
import useReset from '../hooks/useReset';

const CreateProjectDialog = () => {
  const [canCreate] = useCanSave();
  const [createPending] = useCreatePending();
  const [create] = useCreate();
  const [createDialogOpen, setCreateDialogOpen] = useCreateDialogOpen();
  const [reset] = useReset();

  const handleSave = async () => {
    await create();
    reset();
    setCreateDialogOpen(false);
  };

  return (
    <Dialog
      open={createDialogOpen}
      onClose={() => setCreateDialogOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          Create new project
          <Box flex="auto" />
        </Box>
      </DialogTitle>
      <DialogContent>
        <ProjectDetails isNew />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCreateDialogOpen(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={!canCreate || createPending}
          startIcon={createPending && <CircularProgress size={24} color="inherit" />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectDialog;
