import {
  useId,
  useDeviceApp,
  useDescription,
  useDisplayName
} from '../store';

const useReset = () => {
  const [, setDisplayName] = useDisplayName();
  const [, setId] = useId();
  const [, setApp] = useDeviceApp();
  const [, setDescription] = useDescription();

  return [() => {
    setDisplayName('');
    setId('');
    setApp('');
    setDescription('');
  }];
};

export default useReset;
