import { useState } from 'react';
import { createStore } from 'reusable';

export const useSearchPending = createStore(() => useState(false));
export const useResults = createStore(() => useState({}));
export const useHits = createStore(() => useState([]));
export const useIndexError = createStore(() => useState(null));
export const useAddOneHit = () => {
  const [, setHits] = useHits();
  return [hit => {
    setHits(prevHits => ([hit, ...prevHits]));
  }];
};
export const useRemoveOneHit = () => {
  const [, setHits] = useHits();
  return [id => {
    setHits(prevHits => prevHits.filter(({ objectID }) => objectID !== id));
  }];
};

export const useCreateDialogOpen = createStore(() => useState(false));
export const useDisplayName = createStore(() => useState(''));
export const useCanCreate = createStore(() => useState(false));
export const useCreatePending = createStore(() => useState(false));

export const useToken = createStore(() => useState(''));
export const useRefreshPending = createStore(() => useState(false));

export const useDeleteDialogOpen = createStore(() => useState(false));
export const useDeletePending = createStore(() => useState(false));
