import useApiClient from 'hooks/useApiClient';
import { useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';

import { setVarPendingAtom } from '../store';

const useSetVariable = (deviceId, variable) => {
  const apiClient = useApiClient();
  const [, setSnackbarMessage] = useSnackbarMessage();
  const setPending = useSetRecoilState(setVarPendingAtom(variable));

  const setVariable = async (value) => {
    setPending(true);

    try {
      await apiClient.put(`/variables/v1/${deviceId}/variables/${variable}`, { value });
    } catch (err) {
      setSnackbarMessage('Could not set variable');
    } finally {
      setPending(false);
    }
  };

  return [setVariable];
};

export default useSetVariable;
