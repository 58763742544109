import { useEffect } from 'react';
import { useTitle } from '../store';

const usePageTitle = (title) => {
  const [, setTitle] = useTitle();
  useEffect(() => {
    document.title = `${title || ''}${title && ' - '}Project Fireplace`;
    setTitle(title);
  }, [title]);
};

export default usePageTitle;
