import { useRecoilValue } from 'recoil';
import useProjectId from 'hooks/useProjectId';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import { useSnackbarMessage } from 'Snackbar/store';

import { configSelector, revisionSelector } from '../store';

const useSave = (deviceId) => {
  const projectId = useProjectId();
  const thingware = useFireplaceFirebase();
  const revision = useRecoilValue(revisionSelector);
  const config = useRecoilValue(configSelector);
  const [, setSnackbarMessage] = useSnackbarMessage();

  return [async () => {
    try {
      const rev = revision.next();
      await thingware.firestore()
        .doc(`projects/${projectId}/devices/${deviceId}/props/config`)
        .set({ config, rev: rev.toString() });
      setSnackbarMessage('Device configuration saved.');
    } catch {
      setSnackbarMessage('Cloud not save device configuration. Please try again.');
    }
  }];
};

export default useSave;
