import { useSnackbarMessage } from 'Snackbar/store';
import useApiClient from 'hooks/useApiClient';
import { useHistory } from 'react-router-dom';
import {
  useDisplayName,
  useCreatePending,
  useCreateDialogOpen,
} from '../store';

const useSave = () => {
  const apiClient = useApiClient();
  const history = useHistory();
  const [, setCreateDialogOpen] = useCreateDialogOpen();
  const [, setSnackbarMessage] = useSnackbarMessage();
  const [displayName] = useDisplayName();
  const [, setCreatePending] = useCreatePending();

  const create = async () => {
    setCreatePending(true);
    try {
      const { data: { projectId } } = await apiClient.post('/projects/v1/', { displayName });
      history.push(`/projects/${projectId}`);
      setSnackbarMessage('Project created.');
    } catch (err) {
      setSnackbarMessage('Could not create project. Please try again.');
    } finally {
      setCreatePending(false);
      setCreateDialogOpen(false);
    }
  };

  return [create];
};

export default useSave;
