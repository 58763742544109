import React from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import {
  Box,
  Container,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import usePageTitle from 'Projects/hooks/usePageTitle';
import DevicesList from './DevicesList';
import Device from './Device';
import AddDeviceDialog from './AddDeviceDialog';
import List from './List';

const Devices = () => {
  const { breakpoints } = useTheme();
  const wide = useMediaQuery(breakpoints.up('md'));
  usePageTitle('Devices');

  return (
    <Box display="flex" flex="auto">
      <Container disableGutters>
        <Box display="flex" flexDirection="column" height="100%">
          <Switch>
            <Route path="/projects/:projectId/devices" exact>
              <Box pt={1} display="flex" flex="auto">
                <List />
              </Box>
            </Route>

            <Route path="/projects/:projectId/devices/:deviceId">
              <Box display="flex" flex="auto">
                <Box display={wide ? 'flex' : 'none'} flex="auto" width={400} p={2} pt={1}>
                  <DevicesList />
                </Box>
                <Box display="flex" flexGrow={99}>
                  <Device />
                </Box>
              </Box>
            </Route>

          </Switch>

          <AddDeviceDialog />
        </Box>
      </Container>
    </Box>
  );
};

export default Devices;
