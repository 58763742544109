import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Box } from '@material-ui/core';

import Welcome from 'Welcome/components/Welcome';
import Project from 'Projects/components/Project';
import Snackbar from 'Snackbar/Snackbar';
import Authenticated from 'components/Authenticated';
import Projects from 'Projects/components/Projects';

const App = () => (
  <Box height="100vh" width="100vw" top={0} left={0} display="flex" position="absolute">
    <Router basename="/">
      <Switch>
        <Route path="/welcome" component={Welcome} exact />
        <Route path="/projects" exact>
          <Authenticated>
            <Projects />
          </Authenticated>
        </Route>
        <Route path="/projects/:projectId">
          <Authenticated>
            <Project />
          </Authenticated>
        </Route>
        <Redirect from="*" to="/projects" />
      </Switch>
    </Router>
    <Snackbar />
  </Box>
);


export default App;
