import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Scrollable = React.forwardRef(({ children }, ref) => (
  <Box flex="auto" position="relative">
    <Box position="absolute" top={0} left={0} bottom={0} right={0} overflow="auto" ref={ref}>
      {children}
    </Box>
  </Box>
));

Scrollable.propTypes = {
  children: PropTypes.any
};

Scrollable.defaultProps = {
  children: null
};

export default Scrollable;
