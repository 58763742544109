/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';

const Input = React.forwardRef(({
  showLabel,
  label,
  value,
  description,
  required,
  pending,
  readOnly,
  onChange,
  ...rest
}, ref
) => (
  <TextField
    label={!showLabel && label}
    required={required}
    helperText={description || ' '}
    value={value}
    onChange={e => onChange(e.target.value)}
    fullWidth
    InputProps={{
      type: 'number',
      endAdornment: pending && (
        <Box display="flex" alignItems="center">
          <CircularProgress size={24} />
        </Box>
      ),
      readOnly,
      ref,
      ...rest
    }}
  />
));

Input.propTypes = {
  showLabel: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const NumberVariable = (props) => {
  const {
    label,
    tooltip,
    onChange,
    required,
  } = props;
  let { value } = props;
  value = value ?? '';
  const [val, setVal] = useState(value.toString());
  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const isMedium = useMediaQuery(({ breakpoints }) => breakpoints.only('md'));

  const showLabel = (!isSmall && !isMedium);

  useEffect(() => {
    setVal(value.toString());
  }, [value]);

  const handleChange = (v) => {
    setVal(v);
  };

  const handleBlur = useCallback(() => {
    if (!val || isNaN(+val)) {
      return;
    }

    if (val === value.toString()) {
      return;
    }

    onChange(+val);
  }, [value, val, onChange]);

  return (
    <ListItem dense>
      <Box display="flex" width="100%">
        {showLabel && (
          <Box width={240}>
            <ListItemText primary={`${label} ${required ? '*' : ''}`} />
          </Box>
        )}
        <Box flex="auto">
          <Tooltip title={tooltip}>
            <Input
              {...props}
              value={val}
              onChange={handleChange}
              onBlur={handleBlur}
              showLabel={showLabel}
            />
          </Tooltip>
        </Box>
      </Box>
    </ListItem>
  );
};

NumberVariable.propTypes = {
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  pending: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
  description: PropTypes.string,
  tooltip: PropTypes.string,
};

NumberVariable.defaultProps = {
  required: false,
  pending: false,
  readOnly: false,
  label: '',
  value: 0,
  description: '',
  tooltip: '',
};

export default NumberVariable;
