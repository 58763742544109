import { useState } from 'react';
import { createStore } from 'reusable';

export const useNavigationDrawerOpen = createStore(() => useState(false));
export const useTitle = createStore(() => useState(false));
export const useBackTo = createStore(() => useState(null));

export const useSearchPending = createStore(() => useState(false));
export const useLoadMorePending = createStore(() => useState(false));
export const useResults = createStore(() => useState({}));
export const useHits = createStore(() => useState([]));
export const useIndexError = createStore(() => useState(null));
export const useGetOnePending = createStore(() => useState(false));
export const useGetOneResult = createStore(() => useState(null));

export const useCreateDialogOpen = createStore(() => useState(false));
export const useDisplayName = createStore(() => useState(''));
export const useCanSave = createStore(() => useState(false));
export const useCreatePending = createStore(() => useState(false));
export const useSavePending = createStore(() => useState(false));

export const useDeleteDialogOpen = createStore(() => useState(false));
