import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import useProjectId from 'hooks/useProjectId';
import { useSnackbarMessage } from 'Snackbar/store';
import {
  useId,
  useDeviceApp,
  useDisplayName,
  useDescription,
  useSavePending,
  useCreateDialogOpen,
} from '../store';

const useSave = () => {
  const projectId = useProjectId();
  const firebase = useFireplaceFirebase();
  const [, setCreateDialogOpen] = useCreateDialogOpen();
  const [displayName] = useDisplayName();
  const [id] = useId();
  const [app] = useDeviceApp();
  const [description] = useDescription();
  const [, setSavePending] = useSavePending();
  const [, setSnackbarMessage] = useSnackbarMessage();

  return {
    save: async () => {
      setSavePending(true);
      try {
        await firebase.firestore()
          .collection(`projects/${projectId}/devices`)
          .doc(id)
          .set({
            displayName,
            app: app
              ? firebase.firestore().doc(`projects/${projectId}/apps/${app}`)
              : null,
            description,
            platform: firebase.firestore().doc('platforms/esp32')
          });
        setSnackbarMessage('Device saved');
      } catch (err) {
        setSnackbarMessage('Could not save device. Please try again');
      } finally {
        setSavePending(false);
        setCreateDialogOpen(false);
      }
    },
  };
};

export default useSave;
