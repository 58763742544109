import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';

const BooleanConfig = ({
  label,
  description,
  value,
  onChange,
}) => {
  const handleToggle = () => onChange(!value);

  return (
    <ListItem onClick={handleToggle} dense>
      <ListItemText primary={label} secondary={description || ''} />
      <ListItemSecondaryAction>
        <Checkbox checked={value} onClick={handleToggle} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

BooleanConfig.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  description: PropTypes.string,
};

BooleanConfig.defaultProps = {
  label: '',
  value: false,
  description: ''
};

export default BooleanConfig;
