import firebase from 'firebase/app';

firebase.initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
}, 'thingware');

if (process.env.FIREBASE_EMULATOR) {
  firebase.app('thingware').firestore().settings({
    host: 'localhost:8080',
    ssl: false
  });
}

const useFireplaceFirebase = () => firebase.app('thingware');

export default useFireplaceFirebase;
