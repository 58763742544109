import Ajv from 'ajv';
import ajvErrorsPlugin from 'ajv-errors';
import {
  atom,
  atomFamily,
  selector,
  selectorFamily
} from 'recoil';
import { Revision } from 'utils/revision';

const ajv = ajvErrorsPlugin(
  new Ajv({
    useDefaults: true,
    removeAdditional: true,
    allErrors: true,
    jsonPointers: true
  })
);

export const documentState = atom({
  key: 'Variables/document',
  default: {
    revision: Revision.today(),
    variables: {}
  }
});

export const schemaState = atom({
  key: 'Variables/schema',
  default: {
    type: 'object',
    properties: {}
  }
});

export const revisionSelector = selector({
  key: 'Variables/revision',
  get: ({ get }) => get(documentState).rev,
  set: ({ get, set }, rev) => set(documentState, { ...get(documentState), rev })
});

export const variablesSelector = selector({
  key: 'Variables/variables',
  get: ({ get }) => get(documentState).variables,
});

export const namesSelector = selector({
  key: 'Variables/names',
  get: ({ get }) => Object.keys(get(schemaState).properties),
});

export const propertySelector = selectorFamily({
  key: 'Variables/property',
  get: key => ({ get }) => get(schemaState).properties[key]
});

export const valueSelector = selectorFamily({
  key: 'Variables/value',
  get: key => ({ get }) => get(variablesSelector)[key],
});

export const validSelector = selector({
  key: 'Variables/valid',
  get: ({ get }) => {
    const validator = ajv.compile(get(schemaState));
    const valid = validator({ ...get(variablesSelector) });
    const { errors } = validator;

    return { valid, errors };
  }
});

export const setVarPendingAtom = atomFamily({
  key: 'Variables/setVarPending',
  default: false
});
