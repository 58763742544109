const useDownloadJSON = () => {
  const downloadJSON = (obj, filename) => {
    const json = JSON.stringify(obj);
    const blob = new Blob([json], { type: 'application/json;charset=utf8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return [downloadJSON];
};

export default useDownloadJSON;
