import {
  useDisplayName
} from '../store';

const useReset = () => {
  const [, setDisplayName] = useDisplayName();

  return [() => {
    setDisplayName('');
  }];
};

export default useReset;
