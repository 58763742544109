import React, { useEffect, useRef } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import {
  Box, Card,
} from '@material-ui/core';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import usePageTitle from 'Projects/hooks/usePageTitle';
import { useNavTab } from 'Network/store';
import { useParams } from 'react-router-dom';
import Scrollable from 'components/Scrollable';

import CreateAccessPointDialog from './CreateAccessPointDialog';
import DeleteAccessPointDialog from './DeleteAccessPointDialog';
import AccessPoint from './AccessPoint';
import AccessPointsEmpty from './AccessPointsEmpty';
import { accessPointsSelector, accessPointsState, getAllPendingState } from '../store';
import useGetAll from '../hooks/useGetAll';
import useReorder from '../hooks/useReorder';

const SortableListContainer = SortableContainer(({ accessPoints }) => (
  <Box p={1}>
    <Card>
      {accessPoints.map((accessPoint, index) => (
        <AccessPoint key={accessPoint.id} accessPoint={accessPoint} index={index} />
      ))}
    </Card>
  </Box>
));

const AccessPoints = () => {
  usePageTitle('Access Points');
  const [, setNavTab] = useNavTab();
  const reset = useResetRecoilState(accessPointsState);
  const accessPoints = useRecoilValue(accessPointsSelector);
  const getAllPending = useRecoilValue(getAllPendingState);
  const scrollParent = useRef();
  const { deviceId } = useParams();
  const [getAll] = useGetAll(deviceId);
  const [reorder] = useReorder(deviceId);
  const hasEntries = !!accessPoints.length;

  useEffect(() => {
    setNavTab('wi-fi');
  }, []);

  useEffect(() => {
    getAll();
    return reset;
  }, []);


  if (getAllPending) {
    return null;
  }

  return (
    <Box display="flex" flex="auto" flexDirection="column">
      <CreateAccessPointDialog />
      <DeleteAccessPointDialog />
      {hasEntries
        ? (
          <Scrollable ref={scrollParent}>
            <SortableListContainer
              accessPoints={accessPoints}
              useDragHandle
              lockAxis="y"
              transitionDuration={400}
              onSortEnd={({ oldIndex, newIndex }) => reorder(oldIndex, newIndex)}
            />
          </Scrollable>
        )
        : (<AccessPointsEmpty />)}
    </Box>
  );
};

export default AccessPoints;
