import axios from 'axios';
import useFireplaceFirebase from './useFireplaceFirebase';

const useApiClient = () => {
  const firebase = useFireplaceFirebase();
  const apiClient = axios.create();

  apiClient.interceptors.request.use(async (config) => {
    const idToken = await firebase.auth().currentUser.getIdToken();
    config.headers.Authorization = `Bearer ${idToken}`;
    return config;
  });

  apiClient.defaults.baseURL = process.env.API_GATEWAY_URL;

  return apiClient;
};

export default useApiClient;
