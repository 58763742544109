import { padStart } from 'lodash';

const BUILDS_PER_DAY = 10000;
const MSECS_PER_DAY = 86400000;

export class Revision {
  static parse(text) {
    const [year, mon, day, build] = text.split('-');
    return new Revision(+year, +mon, +day, +build.replace('r', ''));
  }

  static today() {
    const today = new Date();
    return new Revision(today.getFullYear(), today.getMonth() + 1, today.getDate(), 0);
  }

  constructor(year, mon, day, build) {
    this.year = year;
    this.mon = mon;
    this.day = day;
    this.build = build;
  }

  toString() {
    return `${padStart(this.year, 4, '0')}-${padStart(this.mon, 2, '0')}-${padStart(this.day, 2, '0')}-r${this.build}`;
  }

  toNumber() {
    const days = new Date(Date.UTC(this.year, this.mon - 1, this.day)).getTime() / MSECS_PER_DAY;
    return days * BUILDS_PER_DAY + this.build;
  }

  next() {
    const today = Revision.today();

    if (this.toNumber() < today.toNumber()) {
      return today;
    }
    return new Revision(this.year, this.mon, this.day, this.build + 1);
  }
}
