import React, { useEffect, useState, useRef } from 'react';
import {
  Tabs,
  Tab,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Menu,
  MenuItem,
  DialogActions,
  Divider,
  ListItemIcon,
  CircularProgress,
  Container,
} from '@material-ui/core';
import {
  MoreVert,
  Delete,
} from '@material-ui/icons';
import {
  Switch,
  Route,
  Redirect,
  Link,
  useHistory
} from 'react-router-dom';
import useProjectId from 'hooks/useProjectId';
import useProjectRoot from 'Projects/hooks/useProjectRoot';
import {
  useDisplayName,
  useBackTo
} from 'Projects/store';
import Tokens from 'DeveloperTokens/components/Tokens';
import useGetOne from 'Projects/hooks/useGetOne';
import useDelete from 'ProjectSettings/hooks/useDelete';
import Details from './Details';
import { useNavTab, useDeletePending } from '../store';

const ProjectSettings = () => {
  const root = useProjectRoot();
  const projectId = useProjectId();
  const { push } = useHistory();
  const menuRef = useRef();
  const [tab] = useNavTab();
  const [, setBackTo] = useBackTo();
  const [name, setDisplayName] = useDisplayName();
  const [deletePending] = useDeletePending();
  const { getOne } = useGetOne();
  const [deleteProject] = useDelete();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setBackTo(null);
  }, []);

  useEffect(() => {
    (async () => {
      const { displayName } = await getOne(projectId);
      setDisplayName(displayName);
    })();
  }, [projectId]);

  const handleDelete = async () => {
    await deleteProject();
    setDeleteDialogOpen(false);
    push('/projects');
  };

  return (
    <Box display="flex" flex="auto">
      <Container disableGutters>
        <Box flex="auto" display="flex" flexDirection="column" height="100%">
          <Box p={2} pb={0} display="flex">
            <Box flex="auto" position="relative">
              <Box position="absolute" width="100%">
                <Tabs value={tab} variant="scrollable">
                  <Tab label="Details" value="details" component={Link} to={`${root}/settings/details`} />
                  <Tab label="Developer Tokens" value="tokens" component={Link} to={`${root}/settings/tokens`} />
                </Tabs>
              </Box>
            </Box>
            <Box>
              <IconButton onClick={() => setMenuOpen(true)} ref={menuRef}>
                <MoreVert />
              </IconButton>
              <Divider />
            </Box>
          </Box>

          <Menu
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            anchorEl={menuRef.current}
          >
            <MenuItem onClick={() => { setDeleteDialogOpen(true); setMenuOpen(false); }}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <Typography variant="inherit">
                Delete
              </Typography>
            </MenuItem>
          </Menu>

          <Switch>
            <Route path="/projects/:projectId/settings/details">
              <Details />
            </Route>
            <Route path="/projects/:projectId/settings/tokens" exact>
              <Tokens />
            </Route>
            <Route path="/projects/:projectId/settings/tokens/:tokenId">
              <Tokens />
            </Route>
            <Redirect from={`${root}/settings`} to={`${root}/settings/details`} />
          </Switch>

          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
            <DialogTitle>
              Delete {name}?
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete {name}? This action cannot be undo.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)} disabled={deletePending}>Cancel</Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleDelete}
                disabled={deletePending}
                startIcon={deletePending && <CircularProgress size={24} color="inherit" />}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Box>
  );
};

export default ProjectSettings;
