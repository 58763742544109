import React from 'react';
import fb from 'firebase/app';
import useRouter from 'use-react-router';
import { Button } from '@material-ui/core';
import useFireplaceFirebase from 'hooks/useFireplaceFirebase';

const LogIn = () => {
  const router = useRouter();
  const firebase = useFireplaceFirebase();

  const handleSignIn = async () => {
    await firebase.auth().signInWithPopup(new fb.auth.GoogleAuthProvider());
    router.history.push('/projects');
  };

  return (
    <Button color="primary" onClick={handleSignIn}>
      sign in with google
    </Button>
  );
};

export default LogIn;
