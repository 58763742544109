import Ajv from 'ajv';
import ajvErrorsPlugin from 'ajv-errors';
import { atom, selector, selectorFamily } from 'recoil';
import { Revision } from 'utils/revision';

const ajv = ajvErrorsPlugin(
  new Ajv({
    useDefaults: true,
    removeAdditional: true,
    allErrors: true,
    jsonPointers: true
  })
);

export const documentState = atom({
  key: 'DeviceConfig/document',
  default: {
    revision: Revision.today(),
    config: {}
  }
});

export const schemaState = atom({
  key: 'DeviceConfig/schema',
  default: {
    type: 'object',
    properties: {}
  }
});

export const revisionSelector = selector({
  key: 'DeviceConfig/revision',
  get: ({ get }) => get(documentState).rev,
  set: ({ get, set }, rev) => set(documentState, { ...get(documentState), rev })
});

export const configSelector = selector({
  key: 'DeviceConfig/config',
  get: ({ get }) => get(documentState).config,
  set: ({ get, set }, config) => set(documentState, { ...get(documentState), config })
});

export const namesSelector = selector({
  key: 'DeviceConfig/names',
  get: ({ get }) => Object.keys(get(schemaState).properties),
});

export const propertySelector = selectorFamily({
  key: 'DeviceConfig/property',
  get: key => ({ get }) => get(schemaState).properties[key]
});

export const valueSelector = selectorFamily({
  key: 'DeviceConfig/value',
  get: key => ({ get }) => get(configSelector)[key],
  set: key => ({ get, set }, value) => set(configSelector, { ...get(configSelector), [key]: value })
});

export const validSelector = selector({
  key: 'DeviceConfig/valid',
  get: ({ get }) => {
    const validator = ajv.compile(get(schemaState));
    const valid = validator({ ...get(configSelector) });
    const { errors } = validator;

    return { valid, errors };
  }
});
