const { atom, selector } = require('recoil');

export const webhookState = atom({
  key: 'Integrations/webhook',
  default: null
});

export const navTabState = atom({
  key: 'Integrations/navTabState',
  default: 'webhook'
});

export const webhookUrlState = selector({
  key: 'Integrations/webhookUrl',
  get: ({ get }) => get(webhookState)?.url ?? '',
  set: ({ set, get }, url) => set(webhookState, { ...(get(webhookState) ?? {}), url }),
});

export const webhookEnabledState = selector({
  key: 'Integrations/webhookEnabled',
  get: ({ get }) => get(webhookState)?.enabled ?? false,
  set: ({ set, get }, enabled) => set(webhookState, { ...(get(webhookState) ?? {}), enabled }),
});

export const getWebhookPendingState = atom({
  key: 'Integrations/getWebhookPendingState',
  default: false
});

export const saveWebhookPendingState = atom({
  key: 'Integrations/saveWebhookPendingState',
  default: false
});
