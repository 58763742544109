import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@material-ui/core';

const BooleanVariable = ({
  label,
  description,
  value,
  tooltip,
  onChange,
  readOnly,
  pending
}) => {
  const handleToggle = () => {
    if (readOnly) {
      return;
    }

    onChange(!value);
  };

  return (
    <ListItem button={!readOnly} onClick={handleToggle} dense>
      <ListItemText primary={label} secondary={description || ''} />
      <ListItemSecondaryAction>
        <Tooltip title={tooltip}>
          {pending ? (
            <Box display="flex" alignItems="center" p={2}>
              <CircularProgress size={24} />
            </Box>
          ) : (<Checkbox checked={value} onClick={handleToggle} disabled={readOnly} />)}
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

BooleanVariable.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  readOnly: PropTypes.bool,
  pending: PropTypes.bool,
  tooltip: PropTypes.string,
  description: PropTypes.string,
};

BooleanVariable.defaultProps = {
  label: '',
  value: false,
  readOnly: false,
  pending: false,
  tooltip: '',
  description: ''
};

export default BooleanVariable;
