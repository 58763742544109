import { useState } from 'react';
import { createStore } from 'reusable';

export const useNavTab = createStore(() => useState('details'));
export const useCreateDialogOpen = createStore(() => useState(false));

export const useSearchPending = createStore(() => useState(false));
export const useLoadMorePending = createStore(() => useState(false));
export const useHits = createStore(() => useState([]));
export const useResults = createStore(() => useState({}));

export const useHasMore = () => useResults(([{ page, nbPages }]) => (nbPages - 1) > page);
export const useAddOneHit = () => {
  const [, setHits] = useHits();
  return [hit => {
    setHits(prevHits => ([hit, ...prevHits]));
  }];
};
export const useDeleteHit = () => {
  const [, setHits] = useHits();
  return [id => {
    setHits(prevHits => prevHits.filter(({ objectID }) => objectID !== id));
  }];
};

export const useFindOnePending = createStore(() => useState(false));
export const useFindOneResult = createStore(() => useState({}));

export const useGetOnePending = createStore(() => useState(false));
export const useGetOneResult = createStore(() => useState({}));

export const useGetConfigFilePending = createStore(() => useState(false));
export const useGetConfigFileResult = createStore(() => useState({}));

export const useGetCredentialsFilePending = createStore(() => useState(false));
export const useGetCredentialsFileResult = createStore(() => useState({}));

export const useSavePending = createStore(() => useState(false));
export const useDeletePending = createStore(() => useState(false));

export const useIndexError = createStore(() => useState(null));
export const useFirestoreError = createStore(() => useState(null));

export const useId = createStore(() => useState(''));
export const useDisplayName = createStore(() => useState(''));
export const useDeviceApp = createStore(() => useState(''));
export const useDescription = createStore(() => useState(''));

export const useCanSave = createStore(() => useState(false));

export const useOnline = createStore(() => useState(false));
