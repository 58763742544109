import useFireplaceFirebase from 'hooks/useFireplaceFirebase';
import useProjectId from 'hooks/useProjectId';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSnackbarMessage } from 'Snackbar/store';
import { accessPointsSelector, accessPointsState } from 'WiFi/store';

const useReorder = (deviceId) => {
  const projectId = useProjectId();
  const client = useFireplaceFirebase();
  const accessPoints = useRecoilValue(accessPointsSelector);
  const setAccessPoints = useSetRecoilState(accessPointsState);
  const [, seSnackbarMessage] = useSnackbarMessage();

  const reorder = async (oldIndex, newIndex) => {
    try {
      const { id } = accessPoints[oldIndex];
      const ref = deviceId
        ? client.firestore()
          .collection('projects')
          .doc(projectId)
          .collection('devices')
          .doc(deviceId)
          .collection('wifi')
          .doc(id)
        : client.firestore()
          .collection('projects')
          .doc(projectId)
          .collection('wifi')
          .doc(id);
      let order = 0;

      // Drop to the top of the list
      if (newIndex === 0) {
        order = accessPoints[0].order / 2;
      } else if (newIndex === accessPoints.length - 1) { // Drop to the bottom of the list
        order = accessPoints[accessPoints.length - 1].order * 2;
      } else { // Drop in between of the 2 elements
        order = (accessPoints[newIndex].order + accessPoints[newIndex - 1].order) / 2;
      }

      setAccessPoints(current => ({ ...current, [id]: { ...current[id], order } }));
      await ref.update({ order });
    } catch (err) {
      console.error(err);
      setAccessPoints(accessPoints);
      seSnackbarMessage('Could not reorder Wi-Fi. Please try again later.');
    }
  };

  return [reorder];
};

export default useReorder;
